import react, { useState } from "react";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import axios from "axios";
import { API_URL } from "../Config";

export default function Login() {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const navigate = useNavigate();
  const handleLogin = async () => {
    if (loginData.email === "" || loginData.password === "") {
      errormessage("Please fill required fields!");
    } else {
      try {
        const res = await axios.post(
          `${API_URL}/api/franchise/auth/login`,
          loginData
        );
        if (res.data.token !== null) {
          localStorage.setItem("mobiletoken", res.data.token);
          window.dispatchEvent(new Event("storage"));
          successmessage("Login successfully done");
          window.location.reload();
        }
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errormessage(error.response.data.message);
        } else {
          errormessage("An unexpected error occurred. Please try again.");
        }
      }
    }
  };

  return (
    <section>
      {contextHolder}
      <Container>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <div style={{ width: "100%" }}>
            <div className="login-page">
              <div className="logo">
                <img src="\assets\logo.png" className="img-fluid" alt="logo" />
              </div>
              <h4 className="login pt-3">Login</h4>
            </div>
            <div className="p-3">
              <div className="login-label">
                <label>
                  Email <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Input
                placeholder="Enter Email Id"
                className="input"
                onChange={(e) =>
                  setLoginData({ ...loginData, email: e.target.value })
                }
              />
              <div className="login-label-1">
                <label>
                  Password <span style={{ color: "red" }}>*</span>
                </label>
              </div>
              <Input.Password
                placeholder="Enter Password"
                className="input"
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
              />
              <div className="d-flex justify-content-center align-items-center py-5">
                <Button className="login-btn" onClick={handleLogin}>
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
