import React from "react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

export default function Navbars() {
  const location = useLocation();

  return (
    <>
      <Navbar fixed="bottom" className="mobile-nav">
        <div className="mobile-nav-combine">
          <Link
            to="/"
            className={`link ${location.pathname === "/" ? "active" : ""}`}
          >
            <div className="navbars">
              <div>
                <i className="fi fi-sr-home icon"></i>
              </div>
              <div className="head-nav">Home</div>
            </div>
          </Link>

          <Link
            to="/orders"
            className={`link ${
              location.pathname === "/orders" ? "active" : ""
            }`}
          >
            <div className="navbars">
              <div>
                <i className="fi fi-sr-order-history icon"></i>
              </div>
              <div className="head-nav">Orders</div>
            </div>
          </Link>

          <Link
            to="/wallet"
            className={`link ${
              location.pathname === "/wallet" ? "active" : ""
            }`}
          >
            <div className="navbars">
              <div>
                <i className="fi fi-sr-wallet-arrow icon"></i>
              </div>
              <div className="head-nav">Wallet</div>
            </div>
          </Link>
        </div>
      </Navbar>
    </>
  );
}
