import axios from 'axios';
import { API_URL } from '../Config';

axios.defaults.withCredentials = true;

const getAuthToken = () => {
    return localStorage.getItem('mobiletoken');
  };
  
  const getHeaders = () => {
    const token = getAuthToken();
    return {
      'Content-Type': 'application/json',
      ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
    };
  };


  export const getuser = async (endpoint: string): Promise<any> => {
    try {
      const response = await axios.get(new URL(endpoint, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw error;
    }
  };

  export const getOrder = async (endpoint: string,id:any): Promise<any> => {
    try {
      const response = await axios.get(new URL(`${endpoint}?assignedFranchise=${id}`, API_URL).toString(), {
        headers: getHeaders(),
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      throw error;
    }
  };

  //Get order By id
  export const getOrderByID = async (endpoint:any,productId:any) => {
    try {
      const response = await axios.get(new URL(endpoint+`/${productId}`,API_URL).toString(), {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };

  //Patch
  export const patchOrder = async (endpoint:any,productId:any,data:any) => {
    try {
      const response = await axios.patch(new URL(endpoint+`/${productId}`,API_URL).toString(),data, {
       headers: getHeaders()
      });
      return response.data;
    } catch (error) {
      console.error('Failed to post data:', error);
      throw error;
    }
  };