import React, { useEffect, useState } from "react";
import Navbars from "../Home/Navbar";
import Header from "../Home/Header";
import { DatePicker, DatePickerProps, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import { Select } from "antd";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { MdClear } from "react-icons/md";
import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { getOrder, getuser, patchOrder } from "../API calls/Apicall";
import { ApiEndPoints } from "../API calls/Api_endpoint";
import { API_URL } from "../Config";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: "100vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

export default function Orders() {
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]); // State to track selected rows
  const [isSelectAllChecked, setIsSelectAllChecked] = useState<boolean>(false); // State to track select all checkbox
  const [activeDiv, setActiveDiv] = useState<"div1" | "div2">("div1");
  const [activeDivs, setActiveDivs] = useState<"divs" | "dive">("divs");

  interface DataType {
    _id: React.Key; // Ensure each item has a unique key
    lineItems: Array<{
      product: {
        image: string;
      };
    }>;
    paymentAmount: number;
    paymentMethod: string;
    deliverySlot: string;
    status: string;
    deliveryDate: string;
  }

  // Function to handle select/deselect all rows
  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      // Select all rows currently displayed
      setSelectedRowKeys(pendingData.map((item: DataType) => item._id));
    } else {
      // Deselect all rows
      setSelectedRowKeys([]);
    }
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(selectedRowKeys);

      setSelectedRowKeys(selectedRowKeys);
      setIsSelectAllChecked(selectedRowKeys.length === pendingData.length); // Update select all checkbox
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.status === "Disabled User", // Adjusted based on status; change if needed
      name: record._id.toString(),
    }),
  };

  const [ogData, setOgData] = useState<DataType[]>([]);
  const [pendingData, setPendingData] = useState<DataType[]>([]);

  const getMe = async () => {
    try {
      const getMeuser = await getuser(ApiEndPoints("getMe"));
      await getOrders(getMeuser.franchise._id);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrders = async (ids: string) => {
    try {
      const getorder = await getOrder(ApiEndPoints("getOrder"), ids);
      setOgData(getorder.orders);
      handleClicks("divs");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClicks = (divName: "divs" | "dive") => {
    if (divName === "divs") {
      const today = moment().format("YYYY-MM-DD");
      const pendData = ogData.filter(
        (val: DataType) =>
          val.status !== "completed" &&
          moment(val.deliveryDate).format("YYYY-MM-DD") === today
      );
      setPendingData(pendData);
    } else {
      const pendData = ogData.filter(
        (val: DataType) => val.status === "completed"
      );
      setPendingData(pendData);
    }
    setActiveDivs(divName);
  };

  const handleClick = (divName: "div1" | "div2") => {
    const today = moment().format("YYYY-MM-DD");
    if (divName === "div1") {
      const pendData = ogData.filter(
        (val: DataType) =>
          val.status !== "completed" &&
          val.deliverySlot === "07:00am-11:00am" &&
          moment(val.deliveryDate).format("YYYY-MM-DD") === today
      );
      setPendingData(pendData);
    } else {
      const pendData = ogData.filter(
        (val: DataType) =>
          val.status !== "completed" &&
          val.deliverySlot === "05:00pm-09:00pm" &&
          moment(val.deliveryDate).format("YYYY-MM-DD") === today
      );
      setPendingData(pendData);
    }
    setActiveDiv(divName);
  };

  const handleupdateOrder = async () => {
    try {
      const requests = selectedRowKeys.map((id) => {
        const data = {
          status: "out for delivery",
          deliveryDate: new Date(),
        };

        return patchOrder(ApiEndPoints("patchOrder"), id, data);
      });

      await Promise.all(requests);
      await getMe();
      
    } catch (error) {
      console.log("Error updating orders:", error);
    }
  };

  const navigate = useNavigate();

  const handleNavDetails = (id: React.Key) => {
    navigate(`/orderdetails/${id}`);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Order Details",
      dataIndex: "lineItems",
      key: "orderDetails",
      render: (_, record: DataType) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={`${API_URL}/images/${record.lineItems[0].product.image}`}
            alt={`Order ${record._id}`}
            style={{ width: 50, height: 50, marginRight: 10 }}
          />
          <p className="txt-3">{record.paymentAmount.toFixed(2)}</p>
          <p className="txt-3">{record.lineItems.length}</p>
          <p className="txt-3">{record.status}</p>
        </div>
      ),
    },
  ];

  const handleChange: DatePickerProps["onChange"] = (date, dateString) => {
    const filterData = ogData.filter(
      (val: DataType) =>
        moment(val.deliveryDate).format("YYYY-MM-DD") ===
          moment(dateString).format("YYYY-MM-DD") && val.status === "completed"
    );
    console.log(filterData);
    setPendingData(filterData);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <Navbars />
      <Header />
      <div className="order-container">
        <div className="pb-4">
          <div className="div-container">
            <div
              onClick={() => handleClicks("divs")}
              className={`div-item ${activeDivs === "divs" ? "active" : ""}`}
            >
              <p className="txt-2">Today Order</p>
            </div>
            <div
              onClick={() => handleClicks("dive")}
              className={`div-item ${activeDivs === "dive" ? "active" : ""}`}
            >
              <p className="txt-2">Completed Order</p>
            </div>
          </div>
        </div>

        {activeDivs === "divs" ? (
          <div>
            <div className="div-container">
              <div
                onClick={() => handleClick("div1")}
                className={`div-item ${activeDiv === "div1" ? "active" : ""}`}
              >
                <p className="txt-2">7am to 11:00am slot</p>
              </div>
              <div
                onClick={() => handleClick("div2")}
                className={`div-item ${activeDiv === "div2" ? "active" : ""}`}
              >
                <p className="txt-2">5pm to 9:00pm slot</p>
              </div>
            </div>

            <div className="table-order pb-5 pt-2">
              <div className="select-all-container">
                <div style={{ marginLeft: "7px" }}>
                  <Checkbox
                    checked={isSelectAllChecked}
                    onChange={handleSelectAllChange}
                    style={{ color: "#0d354f" }}
                  />
                  <label className="txt-1 ms-2">Select All</label>
                </div>
                <div>
                  {selectedRowKeys.length > 0 && (
                    <button
                      className="order-now-button"
                      onClick={handleupdateOrder}
                    >
                      Out for Delivery
                    </button>
                  )}
                </div>
              </div>
              <Table
                rowKey="_id" // Ensure each row has a unique key
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={pendingData}
                pagination={false}
                showHeader={false}
                onRow={(record) => ({
                  onClick: () => navigate(`/orderdetails/${record._id}`),
                })}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="pt-3">
              <DatePicker onChange={handleChange} />
            </div>

            <div className="table-order pb-5 pt-2">
              <div className="pb-5 pt-3">
                {pendingData.map((val: DataType) => (
                  <div key={val._id} className="today-order">
                    <div className="home-div">
                      <div className="img-div">
                        <img
                          className="img-fluid"
                          style={{ width: "40px" }}
                          src={`${API_URL}/images/${val.lineItems[0].product.image}`}
                          alt={`Order ${val._id}`}
                        />
                      </div>
                      <p className="txt-3">{val.paymentAmount.toFixed()}</p>
                      <p className="txt-3">{val.paymentMethod}</p>
                      <p className="txt-3">{val.deliverySlot}</p>
                      <p
                        className="txt-6"
                        onClick={() => handleNavDetails(val._id)}
                        style={{ cursor: "pointer" }}
                      >
                        View
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
