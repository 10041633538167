import React, { useEffect, useState } from "react";
import Header from "../Home/Header";
import Navbars from "../Home/Navbar";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { QRCode } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { getOrder, getuser, patchOrder } from "../API calls/Apicall";
import { ApiEndPoints } from "../API calls/Api_endpoint";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 230,
  bgcolor: "background.paper",
  border: "2px solid white",
  borderRadius: "10px",
  p: 4,
};

export default function OrderDetails() {
  const [isOrderCompleted, setOrderCompleted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [fetching, setFetching] = useState<boolean>(true); // Add fetching state

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    try {
      const getMeuser = await getuser(ApiEndPoints("getMe"));
      await getOrders(getMeuser.franchise._id);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrders = async (ids: string) => {
    setFetching(true); // Start fetching
    try {
      const getorder = await getOrder(ApiEndPoints("getOrder"), ids);
      const detailsData = getorder.orders.filter((val: any) => val._id === id);
      console.log(detailsData);
      
      setData(detailsData);
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false); // End fetching
    }
  };

  const handleOpen = () => {
    setOpen(true);
    setOrderCompleted(true);
  };

  const handleClose = () => setOpen(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await patchOrder(ApiEndPoints("patchOrder"), id, {
        status: "completed",
        paymentStatus: "paid",
        deliveryDate: new Date(),
      });
      setOrderCompleted(false);
      navigate("/orders");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOutfordelivery = async () => {
    try {
      setLoading(true);
      await patchOrder(ApiEndPoints("patchOrder"), id, {
        status: "out for delivery",
        deliveryDate: new Date(),
      });
      setLoading(false);
      navigate("/orders");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Navbars />
      <div className="wallet-container">
        <div className="logo-2">
          <img src="\assets\logo.png" className="img-fluid" alt="logo" />
        </div>
        {fetching ? (
          <div className="text-center my-5">
            <ClipLoader size={50} color={"#0d354f"} loading={fetching} />
          </div>
        ) : (
          <>
            {data.length > 0 && (
              <>
                <div className="pt-4 pb-2">
                  <p className="txt-5">
                    Customer name: <span className="txt-2">{data[0].user.name}</span>
                  </p>
                  <p className="txt-5">
                    Contact no: <span className="txt-2">{data[0].user.phone}</span>
                  </p>
                  <p className="txt-5">
                    Customer address: <span className="txt-2">{data[0].address}</span>
                  </p>
                  <p className="txt-5">
                    Ordered date: <span className="txt-2">{moment(data[0].date).format("YYYY-MM-DD")}</span>
                  </p>
                  <p className="txt-5">
                    Ordered id: <span className="txt-2">{data[0].orderId.slice(-5)}</span>
                  </p>
                  <p className="txt-5">
                    Payment: <span className="txt-2 color">{data[0].paymentMethod}</span>
                  </p>
                  <p className="txt-5">
                    Order Status: <span className="txt-2 color">{data[0].status}</span>
                  </p>
                </div>
                <div style={{ padding: "10px 0px" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                      <TableHead sx={{ backgroundColor: "#0d354f" }}>
                        <TableRow>
                          <TableCell sx={{ width: 10 }} style={{ color: "#fff" }}>
                            S.No
                          </TableCell>
                          <TableCell align="left" sx={{ width: 200 }} style={{ color: "#fff" }}>
                            Item Name
                          </TableCell>
                          <TableCell align="left" sx={{ minWidth: 50 }} style={{ color: "#fff" }}>
                            Gm
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data[0].lineItems.map((item: any, itemIndex: number) => (
                          <TableRow key={itemIndex}>
                            <TableCell component="th" scope="row" className="py-1">
                              {itemIndex + 1}
                            </TableCell>
                            <TableCell className="py-1" align="left">
                              {item.product.name}
                            </TableCell>
                            <TableCell className="py-1" align="left">
                              {item.quantity} g
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell className="py-1"></TableCell>
                          <TableCell className="py-1" style={{ fontWeight: 700 }}>
                            Total
                          </TableCell>
                          <TableCell className="py-1" style={{ fontWeight: 700 }}>
                            ₹ {data[0].paymentAmount.toFixed()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            )}
            {data && data[0] && data[0].status === "pending" ? (
              <div className="text-center pt-4">
                <Button variant="contained" className="complete-btn" onClick={handleOutfordelivery}>
                  Out for delivery
                </Button>
              </div>
            ) : (
              <div className="qr-div pb-5">
                <div className="btn-1">
                  <button className="btn-2" onClick={handleOpen}>
                    Generate QR Code
                  </button>
                </div>
                <div className="btn-1">
                  <div>Or</div>
                </div>
                <div className="btn-1">
                  <button className="btn-2" onClick={() => setOrderCompleted(true)}>
                    Get Cash On Hand
                  </button>
                </div>
                <div>
                  {isOrderCompleted && (
                    <div className="text-center my-3 pt-3 pb-3">
                      <Button
                        variant="contained"
                        className="complete-btn"
                        onClick={handleClick}
                        disabled={loading}
                        startIcon={
                          loading ? (
                            <CircularProgress style={{ color: "#fff" }} size={20} />
                          ) : null
                        }
                      >
                        {loading ? "Updating..." : "Update"}
                      </Button>
                    </div>
                  )}
                </div>

                {data && data[0] && data[0].status === "completed" && (
                  <div className="pb-5 pt-4 d-flex">
                    <div style={{ width: "20%" }}>
                      <hr className="order-hr" />
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ width: "60%" }}
                    >
                      <p className="txt-3">Order has been Completed</p>
                    </div>
                    <div style={{ width: "20%" }}>
                      <hr className="order-hr" />
                    </div>
                  </div>
                )}
              </div>
            )}
            <Modal open={open} onClose={handleClose}>
              <Box sx={style}>
                <QRCode value={`https://meatmarket.live/order/${id}`} />
              </Box>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}
