import React from "react";
import "./App.css";
import Routing from "./Routing/Routing";
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}

export default App;
