import React, { useEffect, useState } from "react";
import Navbars from "../Home/Navbar";
import Header from "../Home/Header";
import { getOrder, getuser } from "../API calls/Apicall";
import { ApiEndPoints } from "../API calls/Api_endpoint";
import moment from "moment";
import { API_URL } from "../Config";
import { DatePicker, DatePickerProps } from "antd";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

export default function Wallet() {
  const [ogData, setOgData] = useState<any>([]);
  const [datas, setDatas] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  const getMe = async () => {
    try {
      const getMeuser = await getuser(ApiEndPoints("getMe"));
      await getOrders(getMeuser.franchise._id);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrders = async (id: string) => {
    setLoading(true); // Start loading
    try {
      const getorder = await getOrder(ApiEndPoints("getOrder"), id);
      let filterdata = getorder.orders.filter(
        (val: any) =>
          moment(val.deliveryDate).format("YYYY-MM-DD") ===
            moment(new Date()).format("YYYY-MM-DD") &&
          val.status === "completed"
      );
      setOgData(filterdata);
      setDatas(filterdata);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleChange: DatePickerProps["onChange"] = (date, dateString) => {
    const filterData = ogData.filter(
      (val: any) =>
        moment(val.deliveryDate).format("YYYY-MM-DD") ===
          moment(dateString).format("YYYY-MM-DD") &&
        val.status === "completed"
    );
    setDatas(filterData);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <Navbars />
      <Header />
      <div className="wallet-container">
        <h4 className="head-txt">Cash In Hand</h4>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%", gap: "20px" }}
        >
          <p className="txt-2" style={{ fontWeight: 600 }}>
            Cash : ₹ 2550
          </p>
          <div>
            <button className="order-now-button">Pay To Hub</button>
          </div>
        </div>

        <div className="pt-3">
          <DatePicker onChange={handleChange} />
        </div>

        <div className="pb-5 pt-3">
          {loading ? (
            <div className="text-center  d-flex justify-content-center align-items-center" style={{height:"50vh"}}>
              <ClipLoader size={50} color={"#0d354f"} loading={loading} />
            </div>
          ) : datas.length > 0 ? (
            <div>
              {datas.map((order: any, orderIndex: any) => (
                <div className="today-order" key={orderIndex}>
                  <div className="home-div">
                    <div className="img-div">
                      <img
                        className="img-fluid"
                        style={{ width: "40px" }}
                        src={`${API_URL}/images/${order.lineItems[0].product.image}`}
                      />
                    </div>
                    <p className="txt-2">{order.paymentAmount.toFixed()}</p>
                    <p className="txt-2">{order.paymentMethod}</p>
                    <p className="txt-2">{order.lineItems.length} items</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <h3 className="text-center">NO orders found!</h3>
          )}
        </div>
      </div>
    </div>
  );
}
