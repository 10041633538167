import react, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Orders from "../Orders/Orders";
import Wallet from "../Wallet/Wallet";
import Login from "../Login/Login";
import OrderDetails from "../Orders/OrderDetails";

export default function Routing() {
  const [isUser, setisUser] = useState(false);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("mobiletoken");
      setisUser(!!token);
    };

    checkToken();

    const handleStorageChange = () => {
      checkToken();
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <BrowserRouter>
      {!isUser ? (
        <Routes>
          <Route path="/" element={<Login />}></Route>
        </Routes>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/orders" element={<Orders />}></Route>
            <Route path="/wallet" element={<Wallet />}></Route>
            <Route path="/orderdetails/:id" element={<OrderDetails />}></Route>
          </Routes>
        </>
      )}
    </BrowserRouter>
  );
}
