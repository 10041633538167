import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { API_URL } from "../Config";
import { message } from "antd";
import { getuser } from "../API calls/Apicall";
import { ApiEndPoints } from "../API calls/Api_endpoint";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface UserData {
  _id: string;
  branchName: string;
  email: string;
  address: string;
}

export default function Header() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Home";

      case "/orders":
        return (
          <>
            <div className="">
              <Link
                to="/"
                className="link d-flex justify-content-start align-items-center"
              >
                <i className="fi fi-rr-arrow-small-left me-1 mt-1"></i>
                Orders
              </Link>
            </div>
          </>
        );

      case "/wallet":
        return "Wallet";

      default:
        if (location.pathname.includes("orderdetails")) {
          return (
            <>
              <div className="d-flex">
                <Link to="/" className="link">
                  <div className="d-flex justify-content-center align-items-center">
                    <i className="fi fi-rr-arrow-small-left"></i>
                  </div>
                </Link>
                &nbsp;
                <div>Order Details</div>
              </div>
            </>
          );
        }
        return null;
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
    });
  };
  const [userData, setUserData] = useState<UserData | null>(null);
  const getMe = async () => {
    try {
      const getMeuser = await getuser(ApiEndPoints("getMe"));
      setUserData(getMeuser.franchise);
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("mobiletoken");
    navigate("/");
    successmessage("Logout success");
    window.location.reload();
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <>
      {contextHolder}
      <div className="header">
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div>{getPageTitle()}</div>
          <div>
            <i className="fi fi-ss-user" onClick={handleOpen}></i>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <label className="py-1">
              Shop ID : {userData && userData._id.slice(-5)}
            </label>
            <br />
            <label className="py-1">
              Shop Name : {userData && userData.branchName}
            </label>
            <br />
            <label className="py-1">
              Mail ID : {userData && userData.email}
            </label>
            <br />
            <label className="py-1">
              Shop Address : {userData && userData.address}
            </label>
            <br />
            <div className="text-center pt-3">
              <Button
                variant="contained"
                className="logout-btn"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
