import React, { useEffect, useState } from "react";
import Navbars from "./Navbar";
import Header from "./Header";
import { ApiEndPoints } from "../API calls/Api_endpoint";
import { getOrder, getuser } from "../API calls/Apicall";
import { API_URL } from "../Config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader"; // Import the spinner

interface Product {
  image: string;
  name: string;
  price: number;
}

interface LineItem {
  id: string;
  product: Product;
  quantity: number;
}

interface Order {
  _id: string;
  lineItems: LineItem[];
  paymentAmount: any;
  paymentMethod: any;
}

export default function Home() {
  const [slot1, setSolt1] = useState<Order[]>([]);
  const [slot2, setSolt2] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true); // State for loading spinner

  const navigate = useNavigate();

  const getMe = async () => {
    try {
      const getMeuser = await getuser(ApiEndPoints("getMe"));
      await getOrders(getMeuser.franchise._id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading spinner once data is fetched
    }
  };

  const getOrders = async (id: string) => {
    try {
      const getorder = await getOrder(ApiEndPoints("getOrder"), id);
      let filterdata = getorder.orders.filter(
        (val: any) =>
          moment(val.deliveryDate).format("YYYY-MM-DD") ===
          moment(new Date()).format("YYYY-MM-DD")
      );
      let filterBysolt1 = filterdata.filter(
        (val: any) => val.deliverySlot === "07:00am-11:00am"
      );
      let filterBysolt2 = filterdata.filter(
        (val: any) => val.deliverySlot === "05:00pm-09:00pm"
      );
      setSolt1(filterBysolt1);
      setSolt2(filterBysolt2);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDetails = (id: any) => {
    navigate(`/orderdetails/${id}`);
  };

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <Navbars />
      <Header />
      <div className="home-container">
        <h5 className="head-txt">Today Orders</h5>

        {/* Show the spinner while loading */}
        {loading ? (
          <div className="spinner-container d-flex justify-content-center align-items-center" style={{height:"50vh"}}>
            <ClipLoader color="#0d354f" loading={loading} size={50} />
          </div>
        ) : (
          <>
            <h5 className="txt-1">07:00am-11:00am slot</h5>
            {slot1.length > 0 ? (
              <div>
                {slot1.map((order, orderIndex) => (
                  <div className="today-order" key={orderIndex}>
                    <div className="home-div">
                      <div className="img-div">
                        <img
                          className="img-fluid"
                          style={{ width: "40px" }}
                          src={`${API_URL}/images/${order.lineItems[0].product.image}`}
                        />
                      </div>
                      <p className="txt-2">{order.paymentAmount.toFixed()}</p>
                      <p className="txt-2">{order.paymentMethod}</p>
                      <p className="txt-2">{order.lineItems.length} items</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <h3 className="text-center">NO orders found!</h3>
            )}

            <h5 className="txt-1">05:00pm-09:00pm slot</h5>
            {slot2.length > 0 ? (
              <div style={{ padding: "10px 0px 50px 0px" }}>
                {slot2.map((order, orderIndex) => (
                  <div
                    className="today-order"
                    key={orderIndex}
                    onClick={() => handleViewDetails(order._id)}
                  >
                    <div className="home-div">
                      <div className="img-div">
                        <img
                          className="img-fluid"
                          style={{ width: "40px" }}
                          src={`${API_URL}/images/${order.lineItems[0].product.image}`}
                        />
                      </div>
                      <p className="txt-2">{order.paymentAmount.toFixed()}</p>
                      <p className="txt-2">{order.paymentMethod}</p>
                      <p className="txt-2">{order.lineItems.length} items</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <h3 className="text-center">NO orders found!</h3>
            )}
          </>
        )}
      </div>
    </div>
  );
}
